import { ContentState, convertFromRaw, EditorState } from 'draft-js';
import { DefaultValues } from 'react-hook-form';

import GetProfileCompanyNameService from '../profiles/get-profile-compamy-name.service';
import DetailedSheetDto from '../../dto/detailed-sheets/out/detailed-sheet.dto';
import ProfileDto from '../../dto/profiles/out/profile.dto';
import DetailedSheetFieldValuesInterface from '../../interfaces/detailed-sheets/detailed-sheet-field-values.interface';

const getProfileCompanyNameService = new GetProfileCompanyNameService();

class GetDetailedSheetDefaultFormValues {
  get(
    profile: ProfileDto,
    detailedSheet: DetailedSheetDto,
  ): DefaultValues<DetailedSheetFieldValuesInterface> {
    return {
      companyName:
        detailedSheet.companyName || getProfileCompanyNameService.get(profile),
      address: detailedSheet.address || profile.address,
      city: detailedSheet.city || profile.city,
      province: detailedSheet.province || profile.province,
      postalCode: detailedSheet.postalCode || profile.postalCode,
      email: detailedSheet.email || '',
      phoneNumber: detailedSheet.phoneNumber || '',
      phoneNumberExt: detailedSheet.phoneNumberExt || '',
      isRbqMember: `${!!(
        detailedSheet.rbqMemberNumber || profile.rbqMemberNumber
      )}`,
      rbqMemberNumber:
        (detailedSheet.rbqMemberNumber || profile.rbqMemberNumber) ?? '',
      websiteUrl: detailedSheet.websiteUrl || '',
      instagramUrl: detailedSheet.instagramUrl || '',
      facebookUrl: detailedSheet.facebookUrl || '',
      linkedinUrl: detailedSheet.linkedinUrl || '',
      videoUrl: detailedSheet.videoUrl || '',
      websiteDescription: EditorState.createWithContent(
        detailedSheet.websiteDescription
          ? convertFromRaw(detailedSheet.websiteDescription)
          : ContentState.createFromText(''),
      ),
      highlightsDescription: EditorState.createWithContent(
        detailedSheet.highlightsDescription
          ? convertFromRaw(detailedSheet.highlightsDescription)
          : ContentState.createFromText(''),
      ),
      sustainableSolutionDescription: EditorState.createWithContent(
        detailedSheet.sustainableSolutionDescription
          ? convertFromRaw(detailedSheet.sustainableSolutionDescription)
          : ContentState.createFromText(''),
      ),
      contestDescription: EditorState.createWithContent(
        detailedSheet.contestDescription
          ? convertFromRaw(detailedSheet.contestDescription)
          : ContentState.createFromText(''),
      ),
      tags: detailedSheet.tags || '',
      activityFields: detailedSheet.detailedSheetActivityFields.map(
        (detailedSheetActivityField) =>
          detailedSheetActivityField.activityField.id,
      ),
      logo: detailedSheet.logo
        ? new File([], detailedSheet.logo.name)
        : undefined,
      image: detailedSheet.image
        ? new File([], detailedSheet.image.name)
        : undefined,
      document1: detailedSheet.detailedSheetDocuments[0]
        ? new File([], detailedSheet.detailedSheetDocuments[0].file.name)
        : undefined,
      document2: detailedSheet.detailedSheetDocuments[1]
        ? new File([], detailedSheet.detailedSheetDocuments[1].file.name)
        : undefined,
      document3: detailedSheet.detailedSheetDocuments[2]
        ? new File([], detailedSheet.detailedSheetDocuments[2].file.name)
        : undefined,
      document1Label: detailedSheet.detailedSheetDocuments[0]
        ? detailedSheet.detailedSheetDocuments[0].label
        : '',
      document2Label: detailedSheet.detailedSheetDocuments[1]
        ? detailedSheet.detailedSheetDocuments[1].label
        : '',
      document3Label: detailedSheet.detailedSheetDocuments[2]
        ? detailedSheet.detailedSheetDocuments[2].label
        : '',
    };
  }
}

export default GetDetailedSheetDefaultFormValues;
