import axios from 'axios';
import { plainToClass } from 'class-transformer';
import { UseFormReturn } from 'react-hook-form';

import DownloadFileFromS3Service from '../files/download-file-from-s3.service';
import GetSignedUrlService from '../files/get-signed-urls.service';
import SaveDetailedSheetDto from '../../dto/detailed-sheets/in/save-detailed-sheet.dto';
import SaveUserDetailedSheetDto from '../../dto/detailed-sheets/in/save-user-detailed-sheet.dto';
import GetSignedUrlDto from '../../dto/files/in/get-signed-url.dto';
import DetailedSheetFieldValuesInterface from '../../interfaces/detailed-sheets/detailed-sheet-field-values.interface';
import { isPlaceholderFile } from '../../utils/files';
import DetailedSheetDto from '../../dto/detailed-sheets/out/detailed-sheet.dto';

const downloadFileFromS3Service = new DownloadFileFromS3Service();
const getSignedUrlService = new GetSignedUrlService();

type UploadedFile = {
  dto: GetSignedUrlDto;
  file: File;
};

class UploadDetailedSheetFilesService {
  async upload(
    detailedSheet: DetailedSheetDto,
    saveDetailedSheetDto: SaveDetailedSheetDto | SaveUserDetailedSheetDto,
    fieldValues: DetailedSheetFieldValuesInterface,
    { getFieldState }: UseFormReturn<DetailedSheetFieldValuesInterface>,
  ): Promise<void> {
    const uploadedFiles: UploadedFile[] = [];

    if (
      fieldValues.logo &&
      getFieldState('logo').isDirty &&
      saveDetailedSheetDto.logo
    ) {
      uploadedFiles.push({
        dto: plainToClass(GetSignedUrlDto, {
          name: saveDetailedSheetDto.logo.key,
          contentType: fieldValues.logo.type,
        }),
        file: fieldValues.logo,
      });
    }

    if (
      fieldValues.image &&
      getFieldState('image').isDirty &&
      saveDetailedSheetDto.image
    ) {
      uploadedFiles.push({
        dto: plainToClass(GetSignedUrlDto, {
          name: saveDetailedSheetDto.image.key,
          contentType: fieldValues.image.type,
        }),
        file: fieldValues.image,
      });
    }

    let documentIndex = 0;

    for (const { file, dirty, label } of [
      {
        file: fieldValues.document1,
        label: fieldValues.document1Label,
        dirty:
          getFieldState('document1').isDirty ||
          getFieldState('document1Label').isDirty,
      },
      {
        file: fieldValues.document2,
        label: fieldValues.document2Label,
        dirty:
          getFieldState('document2').isDirty ||
          getFieldState('document2Label').isDirty,
      },
      {
        file: fieldValues.document3,
        label: fieldValues.document3Label,
        dirty:
          getFieldState('document3').isDirty ||
          getFieldState('document3Label').isDirty,
      },
    ]) {
      if (!file || !label) continue;

      const dtoDocument =
        saveDetailedSheetDto.detailedSheetDocuments[documentIndex];

      if (dirty && dtoDocument) {
        const detailedSheetDocument = detailedSheet.detailedSheetDocuments.find(
          (detailedSheetDocument) =>
            detailedSheetDocument.id === dtoDocument.id,
        );

        const uploadedFile =
          isPlaceholderFile(file) && detailedSheetDocument
            ? await downloadFileFromS3Service.download(
                detailedSheetDocument.file.key,
                detailedSheetDocument.file.name,
              )
            : file;

        uploadedFiles.push({
          dto: plainToClass(GetSignedUrlDto, {
            name: dtoDocument.file.key,
            contentType: uploadedFile.type,
          }),
          file: uploadedFile,
        });
      }

      documentIndex++;
    }

    const signedUrls = await getSignedUrlService.get(
      uploadedFiles.map((uploadedFile) => uploadedFile.dto),
    );

    await Promise.all(
      uploadedFiles.map(async (uploadedFile, index) => {
        const signedUrl = signedUrls[index];

        return axios.put(signedUrl, uploadedFile.file, {
          headers: {
            'Content-Type': uploadedFile.file.type,
          },
        });
      }),
    );
  }
}

export default UploadDetailedSheetFilesService;
