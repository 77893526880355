import { plainToClass } from 'class-transformer';

import SaveDetailedSheetDocumentsDto from '../../../dto/detailed-sheet-documents/save-detailed-sheet-documents.dto';
import DetailedSheetDto from '../../../dto/detailed-sheets/out/detailed-sheet.dto';
import EditionDto from '../../../dto/editions/out/edition.dto';
import SaveFileDto from '../../../dto/files/in/save-file.dto';
import ProfileDto from '../../../dto/profiles/out/profile.dto';
import {
  getFileExtension,
  getFileHash,
  isPlaceholderFile,
  sanitizeFileName,
} from '../../../utils/files';

class MapNewDetailedSheetDocumentToDtoService {
  map(
    edition: EditionDto,
    profile: ProfileDto,
    detailedSheet: DetailedSheetDto,
    documentIndex: number,
    file: File,
    label: string,
  ): SaveDetailedSheetDocumentsDto {
    const detailedSheetDocument =
      detailedSheet.detailedSheetDocuments[documentIndex];

    const fileName = sanitizeFileName(label);
    const extension = getFileExtension(file);
    const hash = getFileHash();

    const fullFileName = `${fileName}-${hash}.${extension}`;

    let contentType = file.type;
    let size = `${file.size}`;
    let originalName = file.name;

    if (isPlaceholderFile(file)) {
      contentType = detailedSheetDocument.file.contentType;
      size = detailedSheetDocument.file.size;
      originalName = detailedSheetDocument.file.originalName;
    }

    return plainToClass(SaveDetailedSheetDocumentsDto, {
      id: detailedSheetDocument?.id,
      file: plainToClass(SaveFileDto, {
        id: detailedSheetDocument?.file.id,
        key: `documents/${edition.name}/profiles/${profile.id}/${fullFileName}`,
        name: fullFileName,
        hasSmallVariant: false,
        extension,
        contentType,
        size,
        originalName,
      }),
      label,
    });
  }
}

export default MapNewDetailedSheetDocumentToDtoService;
