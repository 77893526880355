import { RawDraftContentState } from 'draft-js';

import SaveDetailedSheetDocumentsDto from '../../detailed-sheet-documents/save-detailed-sheet-documents.dto';
import SaveFileDto from '../../files/in/save-file.dto';
import DetailedSheetStatusesEnum from '../../../enums/detailed-sheets/detailed-sheet-statuses.enum';
import ProvincesEnum from '../../../enums/provinces.enum';

class SaveUserDetailedSheetDto {
  id!: string;
  companyName!: string;
  address?: string;
  city?: string;
  province?: ProvincesEnum;
  postalCode?: string;
  email?: string;
  phoneNumber?: string;
  phoneNumberExt?: string;
  rbqMemberNumber?: string;
  websiteUrl?: string;
  videoUrl?: string;
  instagramUrl?: string;
  facebookUrl?: string;
  linkedinUrl?: string;
  websiteDescription!: RawDraftContentState;
  highlightsDescription!: RawDraftContentState;
  sustainableSolutionDescription!: RawDraftContentState;
  contestDescription!: RawDraftContentState;
  tags!: string;
  detailedSheetActivityFields!: {
    id: string;
    activityField: string;
  }[];
  logo!: SaveFileDto;
  image!: SaveFileDto | null;
  status!: DetailedSheetStatusesEnum;
  detailedSheetDocuments!: SaveDetailedSheetDocumentsDto[];
}

export default SaveUserDetailedSheetDto;
