import axios from 'axios';

class DownloadFileFromS3Service {
  async download(key: string, name: string): Promise<File> {
    const { data: blob } = await axios.get(
      `${process.env.REACT_APP_ASSETS_URL}/${key}`,
      { responseType: 'blob' },
    );

    return new File([blob], name, { type: blob.type });
  }
}

export default DownloadFileFromS3Service;
