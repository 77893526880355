import { FC } from 'react';
import { Outlet } from 'react-router-dom';

import Error from '../../Error';
import Loading from '../../Loading';
import useFindActivityFields from '../../../hooks/activity-fields/find-activity-fields.hook';
import useFindUserBooking from '../../../hooks/bookings/find-user-booking.hook';
import useFindUserDetailedSheet from '../../../hooks/detailed-sheets/find-user-detailed-sheet.hook';
import useFindActiveEdition from '../../../hooks/editions/find-active-edition.hook';
import useFindUserProfile from '../../../hooks/profiles/find-user-profile.hook';

const EditDetailedSheetContainer: FC = () => {
  const { data: edition, status: editionStatus } = useFindActiveEdition();
  const { data: profile, status: profileStatus } = useFindUserProfile();
  const { data: booking, status: bookingStatus } = useFindUserBooking();

  const { data: activityFields, status: activityFieldsStatus } =
    useFindActivityFields({ take: 1000 });

  const { data: detailedSheet, status: detailedSheetStatus } =
    useFindUserDetailedSheet();

  if (
    bookingStatus === 'loading' ||
    detailedSheetStatus === 'loading' ||
    editionStatus === 'loading' ||
    profileStatus === 'loading' ||
    activityFieldsStatus === 'loading' ||
    bookingStatus === 'idle' ||
    detailedSheetStatus === 'idle' ||
    editionStatus === 'idle' ||
    profileStatus === 'idle' ||
    activityFieldsStatus === 'idle'
  ) {
    return <Loading />;
  }

  if (
    editionStatus === 'error' ||
    profileStatus === 'error' ||
    bookingStatus === 'error' ||
    activityFieldsStatus === 'error'
  ) {
    return <Error />;
  }

  return (
    <Outlet
      context={{ activityFields, detailedSheet, edition, profile, booking }}
    />
  );
};

export default EditDetailedSheetContainer;
