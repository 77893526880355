import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import Typography from "@mui/material/Typography";
import { FC, useCallback, useState } from "react";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { useOutletContext } from "react-router-dom";
import { useQueryClient } from "react-query";

import AdminActivityFieldsForm from "../../../activity-fields/AdminActivityFieldsForm";
import AdminDetailedSheetCompanyForm from "../../../detailed-sheets/AdminDetailedSheetCompanyForm";
import AdminDetailedSheetDescriptionsForm from "../../../detailed-sheets/AdminDetailedSheetDescriptionsForm";
import AdminDetailedSheetDocumentsForm from "../../../detailed-sheets/AdminDetailedSheetDocumentsForm";
import AdminDetailedSheetInfosForm from "../../../detailed-sheets/AdminDetailedSheetInfosForm";
import AdminDetailedSheetImagesForm from "../../../detailed-sheets/AdminDetailedSheetImagesForm";
import ButtonLoadingIcon from "../../../forms/ButtonLoadingIcon";
import Section from "../../../layouts/Section";
import ProfileTabs from "../../../profiles/ProfileTabs";
import ProfileTitle from "../../../profiles/ProfileTitle";
import AccessOrderDto from "../../../../dto/access-orders/out/access-order.dto";
import ActivityFieldDto from "../../../../dto/activity-fields/activity-field.dto";
import AssemblyDto from "../../../../dto/assemblies/out/assembly.dto";
import BookingDto from "../../../../dto/bookings/out/booking.dto";
import PaginatedResultDto from "../../../../dto/data-tables/pagination/paginated-result.dto";
import DetailedSheetDto from "../../../../dto/detailed-sheets/out/detailed-sheet.dto";
import EditionDto from "../../../../dto/editions/out/edition.dto";
import ProfileDto from "../../../../dto/profiles/out/profile.dto";
import DetailedSheetStatusesEnum from "../../../../enums/detailed-sheets/detailed-sheet-statuses.enum";
import DetailedSheetFieldValuesInterface from "../../../../interfaces/detailed-sheets/detailed-sheet-field-values.interface";
// import useFindDetailedSheetByUserId from '../../../../hooks/detailed-sheets/find-detailed-sheet-by-user-id.hook';
import useApproveDetailedSheet from "../../../../hooks/detailed-sheets/approve-detailed-sheet.hook";
import useSaveDetailedSheet from "../../../../hooks/detailed-sheets/save-detailed-sheet.hook";
import useUnapproveDetailedSheet from "../../../../hooks/detailed-sheets/unapprove-detailed-sheet.hook";
import GetDetailedSheetDefaultFormValues from "../../../../services/detailed-sheets/get-detailed-sheet-default-form-values.service";
import MapFieldValuesToSaveDetailedSheetDto from "../../../../services/detailed-sheets/map-field-values-to-save-detailed-sheet-dto.service";
import UploadDetailedSheetFilesService from "../../../../services/detailed-sheets/upload-detailed-sheet-files.service";
import { toast } from "../../../../utils/toast";
import { onInvalidSubmit } from "../../../../utils/validations";
import { PATHS } from "../../../../utils/paths";

const getDetailedSheetDefaultFormValues =
  new GetDetailedSheetDefaultFormValues();

const mapFieldValuesToSaveDetailedSheetDto =
  new MapFieldValuesToSaveDetailedSheetDto();

const uploadDetailedSheetFilesService = new UploadDetailedSheetFilesService();

const { APPROVED, PROCESSING } = DetailedSheetStatusesEnum;

interface OutletContext {
  accessOrder: AccessOrderDto;
  activityFields: PaginatedResultDto<ActivityFieldDto>;
  assembly: AssemblyDto;
  booking?: BookingDto;
  detailedSheet: DetailedSheetDto;
  edition: EditionDto;
  profile: ProfileDto;
}

const AdminEditDetailedSheetPage: FC = () => {
  // const navigate = useNavigate();

  const {
    accessOrder,
    activityFields,
    assembly,
    booking,
    detailedSheet,
    edition,
    profile,
  } = useOutletContext<OutletContext>();

  const [approved, setApproved] = useState(detailedSheet.status === APPROVED);

  const userId = profile.user.id;

  const queryClient = useQueryClient();
  // const { refetch } = useFindDetailedSheetByUserId(userId);
  const { mutateAsync: approveDetailedSheet } = useApproveDetailedSheet();
  const { mutateAsync: unapproveDetailedSheet } = useUnapproveDetailedSheet();
  const { mutateAsync: saveDetailedSheet } = useSaveDetailedSheet();

  const formMethods = useForm<DetailedSheetFieldValuesInterface>({
    defaultValues: getDetailedSheetDefaultFormValues.get(
      profile,
      detailedSheet,
    ),
  });

  const {
    control,
    formState: { isSubmitting },
    handleSubmit,
    // reset,
    setValue,
  } = formMethods;

  const onSubmit: SubmitHandler<DetailedSheetFieldValuesInterface> =
    useCallback(
      async (fieldValues) => {
        try {
          const dto = mapFieldValuesToSaveDetailedSheetDto.map(
            edition,
            profile,
            detailedSheet,
            fieldValues,
            formMethods,
          );

          await uploadDetailedSheetFilesService.upload(
            detailedSheet,
            dto,
            fieldValues,
            formMethods,
          );

          await saveDetailedSheet(dto);

          // const { data: updatedDetailedSheet } = await refetch();

          // if (updatedDetailedSheet) {
          //   reset({
          //     ...getDetailedSheetDefaultFormValues.get(
          //       profile,
          //       updatedDetailedSheet,
          //     ),
          //   });
          // }

          // navigate(PATHS.admin.detailedSheets.path, {
          //   replace: true,
          // });

          // navigate(PATHS.admin.detailedSheets.edit.path(profile.id), {
          //   replace: true,
          // });

          toast.success("Enregistré avec succès");

          window.location.href = PATHS.admin.detailedSheets.edit.path(
            profile.id,
          );
        } catch (error) {
          toast.error();
        }
      },
      [
        detailedSheet,
        edition,
        profile,
        formMethods,
        // refetch,
        // reset,
        // navigate,
        saveDetailedSheet,
      ],
    );

  const handleApprove = useCallback(async () => {
    try {
      let method = unapproveDetailedSheet;
      let toastMessage = "Approbation retirée avec succès";
      let nextApprovedState = false;
      let status = PROCESSING;

      if (!approved) {
        method = approveDetailedSheet;
        toastMessage = "Approuvé avec succès";
        nextApprovedState = true;
        status = APPROVED;
      }

      await method({ id: detailedSheet.id });

      queryClient.setQueryData<DetailedSheetDto | undefined>(
        ["users/detailed-sheet", userId],
        (detailedSheet) => {
          if (detailedSheet) {
            return {
              ...detailedSheet,
              status,
            };
          }
        },
      );

      setApproved(nextApprovedState);

      toast.success(toastMessage);
    } catch (error) {
      toast.error();
    }
  }, [
    detailedSheet,
    approved,
    queryClient,
    userId,
    approveDetailedSheet,
    setApproved,
    unapproveDetailedSheet,
  ]);

  return (
    <>
      <ProfileTitle
        accessOrder={accessOrder}
        assembly={assembly}
        detailedSheet={detailedSheet}
        profile={profile}
      />

      <ProfileTabs booking={booking} profile={profile} value="detailed-sheet" />

      <FormProvider {...formMethods}>
        <form onSubmit={handleSubmit(onSubmit, onInvalidSubmit)}>
          <Section>
            <Typography variant="overline" component="h2" sx={{ mb: 2 }}>
              Entreprise
            </Typography>

            <AdminDetailedSheetCompanyForm
              control={control}
              booking={booking}
              profile={profile}
              setValue={setValue}
            />
          </Section>

          <Section>
            <Typography variant="overline" component="h2" sx={{ mb: 2 }}>
              Votre présence en ligne
            </Typography>

            <AdminDetailedSheetInfosForm control={control} />
          </Section>

          <Section>
            <Typography variant="overline" component="h2" sx={{ mb: 2 }}>
              Descriptions
            </Typography>

            <AdminDetailedSheetDescriptionsForm control={control} />
          </Section>

          <Section>
            <Typography variant="overline" component="h2" sx={{ mb: 2 }}>
              Images
            </Typography>

            <AdminDetailedSheetImagesForm
              control={control}
              detailedSheet={detailedSheet}
            />
          </Section>

          <Section>
            <Typography variant="overline" component="h2" sx={{ mb: 2 }}>
              Documents
            </Typography>

            <Typography variant="body1" sx={{ mb: 4}}>
              Vous pouvez déposer jusqu&lsquo;à 3 documents. Ils seront accessibles dans votre fiche sur le site internet. Le libellé indiqué apparaitra comme un lien cliquable vers le document. 
            </Typography>

            <AdminDetailedSheetDocumentsForm detailedSheet={detailedSheet} />
          </Section>

          <Section>
            <Typography variant="overline" component="h2" sx={{ mb: 2 }}>
              Catégories
            </Typography>

            <AdminActivityFieldsForm activityFields={activityFields} />
          </Section>

          <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
            <FormControlLabel
              sx={{ mr: 8 }}
              control={
                <Switch
                  defaultChecked={approved}
                  value={approved}
                  onChange={handleApprove}
                />
              }
              label="Approuvé"
            />

            <Button
              disabled={isSubmitting}
              type="submit"
              variant="contained"
              sx={{ float: "right" }}
              startIcon={isSubmitting && <ButtonLoadingIcon />}
            >
              Enregistrer
            </Button>
          </Box>
        </form>
      </FormProvider>
    </>
  );
};

export default AdminEditDetailedSheetPage;
