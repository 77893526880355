import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import bytes from 'bytes';
import { FC, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';

import Dropzone from '../forms/Dropzone';
import FormGrid from '../forms/FormGrid';
import FormStack from '../forms/FormStack';
import TextField from '../forms/TextField';
import DetailedSheetDto from '../../dto/detailed-sheets/out/detailed-sheet.dto';
import DetailedSheetFieldValuesInterface from '../../interfaces/detailed-sheets/detailed-sheet-field-values.interface';
import GetFilePreviewUrlService from '../../services/files/get-file-preview-url.service';
import { validateRequired } from '../../utils/validations';

const ACCEPT = { 'application/pdf': [], 'image/jpg': [], 'image/png': [] };
const MIN_IMAGE_SIZE = bytes('0Mb');
const MAX_IMAGE_SIZE = bytes('2Mb');

const getFilePreviewUrlService = new GetFilePreviewUrlService();

interface Props {
  detailedSheet: DetailedSheetDto;
}

const AdminDetailedSheetDocumentsForm: FC<Props> = ({ detailedSheet }) => {
  const { control, clearErrors, setValue, watch } =
    useFormContext<DetailedSheetFieldValuesInterface>();

  const hasDocument1 = !!watch('document1');
  const hasDocument1Label = !!watch('document1Label');
  const hasDocument2 = !!watch('document2');
  const hasDocument2Label = !!watch('document2Label');
  const hasDocument3 = !!watch('document3');
  const hasDocument3Label = !!watch('document3Label');

  useEffect(() => {
    if (!hasDocument1) {
      clearErrors('document1Label');
      setValue('document1Label', '');
    }
  }, [hasDocument1, clearErrors, setValue]);

  useEffect(() => {
    if (!hasDocument1Label) clearErrors('document1');
  }, [hasDocument1Label, clearErrors, setValue]);

  useEffect(() => {
    if (!hasDocument2) {
      clearErrors('document2Label');
      setValue('document2Label', '');
    }
  }, [hasDocument2, clearErrors, setValue]);

  useEffect(() => {
    if (!hasDocument2Label) clearErrors('document2');
  }, [hasDocument2Label, clearErrors, setValue]);

  useEffect(() => {
    if (!hasDocument3) {
      clearErrors('document3Label');
      setValue('document3Label', '');
    }
  }, [hasDocument3, clearErrors, setValue]);

  useEffect(() => {
    if (!hasDocument3Label) clearErrors('document3');
  }, [hasDocument3Label, clearErrors, setValue]);

  return (
    <FormStack>
      <FormGrid>
        <Grid item>
          <Typography variant="h5" sx={{ mt: 2, mb: 2 }}>
            Premier document (facultatif)
          </Typography>

          <Dropzone
            control={control}
            name="document1"
            label={`Déposer un fichier de format pdf, jpg ou png`}
            description="Le fichier ne doit pas excéder 2mo."
            preview={
              detailedSheet.detailedSheetDocuments[0] &&
              getFilePreviewUrlService.get(
                detailedSheet.detailedSheetDocuments[0].file.key,
                detailedSheet.detailedSheetDocuments[0].file.contentType,
              )
            }
            options={{
              accept: ACCEPT,
              minSize: MIN_IMAGE_SIZE,
              maxSize: MAX_IMAGE_SIZE,
              multiple: false,
            }}
            rules={validateRequired(hasDocument1Label)}
          />

          <TextField
            control={control}
            name="document1Label"
            label="Libellé du document"
            rules={validateRequired(hasDocument1)}
          />
        </Grid>

        <Grid item>
          <Typography variant="h5" sx={{ mt: 4, mb: 2 }}>
            Second document (facultatif)
          </Typography>

          <Dropzone
            control={control}
            name="document2"
            label={`Déposer un fichier de format pdf, jpg ou png`}
            description="Le fichier ne doit pas excéder 2mo."
            preview={
              detailedSheet.detailedSheetDocuments[1] &&
              getFilePreviewUrlService.get(
                detailedSheet.detailedSheetDocuments[1].file.key,
                detailedSheet.detailedSheetDocuments[1].file.contentType,
              )
            }
            options={{
              accept: ACCEPT,
              minSize: MIN_IMAGE_SIZE,
              maxSize: MAX_IMAGE_SIZE,
              multiple: false,
            }}
            rules={validateRequired(hasDocument2Label)}
          />

          <TextField
            control={control}
            name="document2Label"
            label="Libellé du document"
            rules={validateRequired(hasDocument2)}
          />
        </Grid>

        <Grid item>
          <Typography variant="h5" sx={{ mt: 2, mb: 2 }}>
            Troisième document (facultatif)
          </Typography>
          
          <Dropzone
            control={control}
            name="document3"
            label={`Déposer un fichier de format pdf, jpg ou png`}
            description="Le fichier ne doit pas excéder 2mo."
            preview={
              detailedSheet.detailedSheetDocuments[2] &&
              getFilePreviewUrlService.get(
                detailedSheet.detailedSheetDocuments[2].file.key,
                detailedSheet.detailedSheetDocuments[2].file.contentType,
              )
            }
            options={{
              accept: ACCEPT,
              minSize: MIN_IMAGE_SIZE,
              maxSize: MAX_IMAGE_SIZE,
              multiple: false,
            }}
            rules={validateRequired(hasDocument3Label)}
          />

          <TextField
            control={control}
            name="document3Label"
            label="Libellé du document"
            rules={validateRequired(hasDocument3)}
          />
        </Grid>
      </FormGrid>
    </FormStack>
  );
};

export default AdminDetailedSheetDocumentsForm;
