import { plainToClass } from 'class-transformer';

import SaveDetailedSheetDocumentsDto from '../../../dto/detailed-sheet-documents/save-detailed-sheet-documents.dto';
import DetailedSheetDto from '../../../dto/detailed-sheets/out/detailed-sheet.dto';

import SaveFileDto from '../../../dto/files/in/save-file.dto';

class MapExistingDetailedSheetDocumentsToDtoService {
  map(
    detailedSheet: DetailedSheetDto,
    documentIndex: number,
  ): SaveDetailedSheetDocumentsDto | undefined {
    const detailedSheetDocument =
      detailedSheet.detailedSheetDocuments[documentIndex];

    if (!detailedSheetDocument) return;

    return plainToClass(SaveDetailedSheetDocumentsDto, {
      id: detailedSheetDocument.id,
      label: detailedSheetDocument.label,
      file: plainToClass(SaveFileDto, {
        id: detailedSheetDocument.file.id,
        key: detailedSheetDocument.file.key,
        name: detailedSheetDocument.file.name,
        extension: detailedSheetDocument.file.extension,
        contentType: detailedSheetDocument.file.contentType,
        size: detailedSheetDocument.file.size,
        originalName: detailedSheetDocument.file.originalName,
      }),
    });
  }
}

export default MapExistingDetailedSheetDocumentsToDtoService;
