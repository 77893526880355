import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import ListItemIcon from '@mui/material/ListItemIcon';
import Typography from '@mui/material/Typography';
import { FiFileText } from 'react-icons/fi';
import { FC } from 'react';

import FormGrid from '../forms/FormGrid';
import FormStack from '../forms/FormStack';
import Section from '../layouts/Section';
import DetailedSheetDto from '../../dto/detailed-sheets/out/detailed-sheet.dto';

interface Props {
  detailedSheet: DetailedSheetDto;
}

const DetailedSheetDocumentsSection: FC<Props> = ({ detailedSheet }) => {
  return (
    <Section>
      <Typography variant="overline" component="h2" sx={{ mb: 4 }}>
        Documents
      </Typography>

      <FormStack>
        <FormGrid>
          {detailedSheet.detailedSheetDocuments.map(({ id, file, label }) => (
            <Grid key={id} item xs={12} sx={{ pt: 0 }}>
              <ListItemIcon
                sx={{
                  color: 'grey.800',
                  mr: 1,
                  minWidth: 0,
                  position: 'relative',
                  top: '2px',
                }}
              >
                <FiFileText />
              </ListItemIcon>

              <Link
                href={`${process.env.REACT_APP_ASSETS_URL}/${file.key}`}
                target="_blank"
              >
                {label}
              </Link>
            </Grid>
          ))}
        </FormGrid>
      </FormStack>
    </Section>
  );
};

export default DetailedSheetDocumentsSection;
