import { UseFormReturn } from 'react-hook-form';

import MapExistingDetailedSheetDocumentsToDtoService from './map-existing-detailed-sheet-document-to-dto.service';
import MapNewDetailedSheetDocumentToDtoService from './map-new-detailed-sheet-document-to-dto.service';
import SaveDetailedSheetDocumentsDto from '../../../dto/detailed-sheet-documents/save-detailed-sheet-documents.dto';
import DetailedSheetDto from '../../../dto/detailed-sheets/out/detailed-sheet.dto';
import EditionDto from '../../../dto/editions/out/edition.dto';
import ProfileDto from '../../../dto/profiles/out/profile.dto';
import DetailedSheetFieldValuesInterface from '../../../interfaces/detailed-sheets/detailed-sheet-field-values.interface';

type DetailedSheetFieldValuesKey = keyof DetailedSheetFieldValuesInterface;

const mapExistingDetailedSheetDocumentsToDtoService =
  new MapExistingDetailedSheetDocumentsToDtoService();

const mapNewDetailedSheetDocumentToDtoService =
  new MapNewDetailedSheetDocumentToDtoService();

class MapToSaveDetailedSheetDocumentsDto {
  map(
    edition: EditionDto,
    profile: ProfileDto,
    detailedSheet: DetailedSheetDto,
    fieldValues: DetailedSheetFieldValuesInterface,
    formMethods: UseFormReturn<DetailedSheetFieldValuesInterface>,
  ): SaveDetailedSheetDocumentsDto[] {
    const {
      document1,
      document2,
      document3,
      document1Label,
      document2Label,
      document3Label,
    } = fieldValues;
    const { getFieldState } = formMethods;

    const dtos: SaveDetailedSheetDocumentsDto[] = [];

    let index = 0;

    for (const { file, fileFieldName, label, labelFieldName } of [
      {
        file: document1,
        fileFieldName: 'document1' as DetailedSheetFieldValuesKey,
        label: document1Label,
        labelFieldName: 'document1Label' as DetailedSheetFieldValuesKey,
      },
      {
        file: document2,
        fileFieldName: 'document2' as DetailedSheetFieldValuesKey,
        label: document2Label,
        labelFieldName: 'document2Label' as DetailedSheetFieldValuesKey,
      },
      {
        file: document3,
        fileFieldName: 'document3' as DetailedSheetFieldValuesKey,
        label: document3Label,
        labelFieldName: 'document3Label' as DetailedSheetFieldValuesKey,
      },
    ]) {
      if (!file || !label) continue;

      const isDirty =
        getFieldState(fileFieldName).isDirty ||
        getFieldState(labelFieldName).isDirty;

      if (isDirty) {
        const dto = mapNewDetailedSheetDocumentToDtoService.map(
          edition,
          profile,
          detailedSheet,
          index,
          file,
          label,
        );

        dtos.push(dto);

        index++;

        continue;
      }

      const dto = mapExistingDetailedSheetDocumentsToDtoService.map(
        detailedSheet,
        index,
      );

      if (dto) dtos.push(dto);

      index++;
    }

    return dtos;
  }
}

export default MapToSaveDetailedSheetDocumentsDto;
