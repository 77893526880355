import FormHelperText from '@mui/material/FormHelperText';
import FormLabel from '@mui/material/FormLabel';
import { FC, useCallback, useMemo } from 'react';
import { useController, useFormContext } from 'react-hook-form';

import CheckboxGroup from '../forms/CheckboxGroup';
import ActivityFieldDto from '../../dto/activity-fields/activity-field.dto';
import PaginatedResultDto from '../../dto/data-tables/pagination/paginated-result.dto';
import DetailedSheetFieldValuesInterface from '../../interfaces/detailed-sheets/detailed-sheet-field-values.interface';
import { validateArrayRequired } from '../../utils/validations';

interface Props {
  activityFields: PaginatedResultDto<ActivityFieldDto>;
}

const ActivityFieldsForm: FC<Props> = ({ activityFields }) => {
  const { control } = useFormContext<DetailedSheetFieldValuesInterface>();

  const {
    field: { value, onChange },
    fieldState: { error },
  } = useController({
    name: 'activityFields',
    rules: validateArrayRequired(1, 2),
    control,
  });

  const options = useMemo(() => {
    return activityFields.nodes.map((activityField) => {
      const { id, closed } = activityField;

      return {
        label: activityField.name,
        value: id,
        disabled: closed,
      };
    });
  }, [activityFields]);

  const isDisabled = useCallback(
    (selection: string[], category: string): boolean => {
      return !selection.includes(category) && selection.length >= 2;
    },
    [],
  );

  return (
    <>
      <FormLabel>
        Choisir 1 à 2 catégories correspondant à votre entreprise.
        {!!error && <FormHelperText error>{error.message}</FormHelperText>}
      </FormLabel>

      <CheckboxGroup
        sx={{
          backgroundColor: 'grey.200',
          display: 'block',
          padding: '15px 20px 10px 20px !important',
          marginBottom: '20px',
          mt: '20px !important',
        }}
        disabled={isDisabled}
        error={error}
        onChange={(value): void => onChange(value as string[])}
        options={options}
        value={value}
        row
      />
    </>
  );
};

export default ActivityFieldsForm;
