import { DEFAULT_FILE_PREVIEW_URL } from '../../utils/files';

class GetFilePreviewUrlService {
  get(key: string, type: string): string {
    if (type.startsWith('image/')) {
      return `${process.env.REACT_APP_ASSETS_URL}/${key}`;
    }

    return DEFAULT_FILE_PREVIEW_URL;
  }
}

export default GetFilePreviewUrlService;
